import SettingsIcon from "@mui/icons-material/Settings";
import { Menu, Tooltip } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import * as React from "react";
import { ScrollRestoration, Link as domLink } from "react-router-dom";
import LogoImage from "../HLBMEISEI-logo.png";
import theme from "../style/theme";
import Loading from "./Loading";
import { default as SettingMenuList } from "./SettingMenuList";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      position="absolute"
      bottom="0"
      width="100%"
      {...props}
    >
      {"Copyright 2024 "}
      <Link color="inherit" href="https://www.hlbmeisei.jp/">
        HLB Meisei LLC.
      </Link>
    </Typography>
  );
}

const drawerWidth = 240;

//TODO:drawerの開閉処理削除
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function SettingMenu({ settingLinkList }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div marginleft="1%">
      <Tooltip title="設定">
        <>
          <IconButton
            color="inherit"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <SettingsIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <SettingMenuList settingLinkList={settingLinkList} />
          </Menu>
        </>
      </Tooltip>
    </div>
  );
}

function HeaderLinkButtons({ headerLinkList }) {
  const LinkList = headerLinkList.map((item) => {
    return (
      <Link
        color="inherit"
        noWrap
        to={item.href}
        marginLeft="1%"
        underline="none"
        key={item.href}
        component={domLink}
      >
        {item.text}
      </Link>
    );
  });

  return <>{LinkList}</>;
}

const Layout = ({ headerLinkList, settingLinkList, children }) => {
  const [open] = React.useState(false);
  // TODO:コメントアウトしたcss不要？
  return (
    <ThemeProvider theme={theme}>
      <Loading />
      <AppBar
        // position="absolute"
        open={open}
        // style={{ padding: "0" }}
      >
        <Toolbar>
          <img
            src={LogoImage}
            alt=""
            style={{ height: "100%", width: "auto", marginRight: "1%" }}
          ></img>
          <Link
            variant="h6"
            color="inherit"
            noWrap
            to="./"
            sx={{ flexGrow: 1 }}
            underline="none"
            component={domLink}
          >
            HLB Meisei有限責任監査法人
          </Link>
          {headerLinkList && (
            <HeaderLinkButtons headerLinkList={headerLinkList} />
          )}
          {settingLinkList && <SettingMenu settingLinkList={settingLinkList} />}
        </Toolbar>
      </AppBar>
      <Box
      // sx={{ display: "flex" }}
      >
        <CssBaseline />
        <ScrollRestoration />
        <Box
          component="main"
          // sx={{
          //   backgroundColor: (theme) =>
          //     theme.palette.mode === "light"
          //       ? theme.palette.grey[100]
          //       : theme.palette.grey[900],
          //   flexGrow: 1,
          //   height: "100vh",
          //   overflow: "auto",
          // }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {children}
          </Container>
        </Box>
      </Box>
      <Copyright sx={{ pt: 4, position: "sticky", top: "100vh" }} />
    </ThemeProvider>
  );
};

export default Layout;
