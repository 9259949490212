import { MenuItem, Select } from "@mui/material";

const SelectComponent = ({ choices, disabled = false }) => {
  return ({ rowData, setRowData }) => {
    return (
      <Select
        disabled={disabled}
        value={
          choices
            ?.map(({ value }) => value)
            .find((value) => value === rowData) ?? null
        }
        onChange={(choice) => {
          if (choice === null) return;
          setRowData(choice.target.value);
        }}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          minWidth: "0",
        }}
      >
        {choices?.map((choice) => (
          <MenuItem key={choice.value} value={choice.value}>
            {choice.label}
          </MenuItem>
        ))}
      </Select>
    );
  };
};

const selectColumn = (props) => ({
  component: SelectComponent(props),
});

export default selectColumn;
