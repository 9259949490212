/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeProvider } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DefaultPaper from "../../components/DefaultPaper";
import { default as CompanyForm } from "../../components/Form/CompanyForm";
import CompanyPersonnelForm from "../../components/Form/CompanyPersonnelForm";
import TargetPeriodForm from "../../components/Form/TargetPeriodForm";
import FormDialog from "../../components/FormDialog";
import TextFieldWithTooltip from "../../components/Input/TextFieldWithTooltip";
import AddEditDialogTable from "../../components/Table/AddEditDialogTable";
import useApi, { httpClient } from "../../hooks/useApi";
import { infoTheme } from "../../style/theme";
import getCompanyPersonnelListHead, {
  companyPersonnelData,
  companyPersonnelDataMapper,
} from "../../utils/CompanyPersonnelListHead";
import Constants from "../../utils/Constants";
import getTargetPeriodListHead, {
  targetPeriodData,
} from "../../utils/TargetPeriodListHead";

const Company = ({ title }) => {
  const params = useParams();
  const role = useSelector((state) => state.role.role);
  const { connect } = useApi();
  const navigate = useNavigate();
  // API GET
  const [companyData, setCompanyData] = useState({});
  const [fetchResult, setFetchResult] = useState();
  const fetchData = async () => {
    // 会社情報取得
    const companyResult = await connect(
      () => {
        return httpClient.get(`../../api/auditor/GetCompany?id=${params.id}`);
      },
      null,
      true
    );
    setCompanyData(companyResult?.data?.data ?? []);
    setFetchResult(companyResult?.data);
  };
  useEffect(() => {
    if (params?.id === "new" && role === Constants.ROLE.CLIENT) {
      throw new Error("404：データが見つかりません");
    }
    fetchData();
  }, [params, role]);
  //会社編集関連
  const [companyEditDialogOpen, setCompanyEditDialogOpen] = useState(
    params.id === "new"
  );
  const handleCloseCompanyEdit = (result) => {
    if (result?.status === 200) {
      setCompanyEditDialogOpen(false);
      if (params.id === "new") {
        navigate(`../company/${result.data}`);
      } else {
        fetchData();
      }
    }
  };

  //会社担当者招待関連
  const [
    clientPersonnelInviteDialogOpen,
    setClientPersonnelInvitationDialogOpen,
  ] = useState(false);
  const [clientPersonnelUpdateCount, setClientPersonnelUpdateCount] =
    useState(0);
  const handleClickInviteClientPersonnel = () => {
    setClientPersonnelInvitationDialogOpen(true);
  };
  const handleClickInvitationConfirm = () => {
    setClientPersonnelInvitationDialogOpen(false);
    const invite = async () => {
      const result = await connect(() => {
        return httpClient.post(
          "../../api/auditor/InviteCompanyPersonnel",
          params.id
        );
      }, "アカウントの招待を行いました");
      setClientPersonnelUpdateCount(clientPersonnelUpdateCount + 1);
      // TODO:開発用
      console.log("result", result);
    };
    invite();
  };
  const handleCancelInvitationConfirm = (value) => {
    setClientPersonnelInvitationDialogOpen(false);
  };

  // 対象期間一覧関連
  const [displayTargetPeriodList, setDisplayTargetPeriodList] = useState(false);
  useEffect(
    // 一覧の表示制御
    () =>
      setDisplayTargetPeriodList(
        companyData?.clientId === companyData?.id &&
          role === Constants.ROLE.AUDITOR
      ),
    [role, companyData]
  );

  //会社情報確認依頼関連
  // const [
  //   companyInfoConfirmationRequestDialogOpen,
  //   setCompanyInfoConfirmationRequestDialogOpen,
  // ] = useState(false);
  // const handleCloseCompanyInfoConfirmationRequestDialog = () => {
  //   setCompanyInfoConfirmationRequestDialogOpen(false);
  // };
  // const [requestDialogOpen, setRequestDialogOpen] = useState(false);
  // const handleCloseRequest = () => {
  //   setRequestDialogOpen(false);
  // };
  // const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  // const handleCloseResponse = () => {
  //   setResponseDialogOpen(false);
  // };

  //会社担当者一括編集関連
  // const [
  //   companyPersonnelSpreadsheetDialogOpen,
  //   setCompanyPersonnelSpreadsheetDialogOpen,
  // ] = useState(false);
  // const handleCloseCompanyPersonnelSpreadsheetDialog = () => {
  //   setCompanyPersonnelSpreadsheetDialogOpen(false);
  // };

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <DefaultPaper title="会社情報">
        <Grid container>
          <ThemeProvider theme={infoTheme}>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="クライアントコード"
                value={
                  companyData?.clientId === companyData?.id
                    ? companyData?.companyCode
                    : fetchResult?.clientData?.companyCode
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="会社コード"
                value={companyData?.companyCode}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="会社略称"
                value={companyData?.companyAbbreviation}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="会社名"
                value={companyData?.companyName}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="監査担当チーム"
                value={
                  fetchResult?.teamList?.find(
                    (team) =>
                      team.id ===
                      (companyData?.clientId === companyData?.id
                        ? companyData?.teamId
                        : fetchResult?.clientData?.teamId)
                  )?.teamName
                }
              />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="入力ステータス"
                value={
                  fetchResult?.companyRegisterStatusType?.find(
                    (type) => type.code === companyData?.registerStatus
                  )?.name
                }
              />
            </Grid>
          </ThemeProvider>
          <Grid item xs={6}>
            <Button onClick={() => setCompanyEditDialogOpen(true)}>編集</Button>
          </Grid>
          {/* Step2以降 */}
          {/* <Grid item xs={5} textAlign={"right"}>
              <Button
                onClick={() =>
                  setCompanyInfoConfirmationRequestDialogOpen(true)
                }
              >
                会社情報確認依頼
              </Button>
            </Grid> */}
        </Grid>
      </DefaultPaper>

      <DefaultPaper title={"担当者一覧"}>
        <Grid container>
          <Grid item xs={6}>
            {/* Step2以降 */}
            {/* (
              <Button
                startIcon={<Edit />}
                onClick={() => setCompanyPersonnelSpreadsheetDialogOpen(true)}
              >
                一括編集
              </Button>
            ) */}
          </Grid>
          <Grid item xs={5.8} textAlign={"right"}>
            <Button
              type="submit"
              color="primary"
              sx={{ marginTop: "0.5rem" }}
              onClick={handleClickInviteClientPersonnel}
            >
              担当者アカウントの招待
            </Button>
          </Grid>
          <Grid item xs={0.2} />
          <Grid item xs={12}>
            <AddEditDialogTable
              getPath={`../../api/auditor/GetClientInCharge?companyId=${params.id}`}
              deletePath="../../api/auditor/DeleteClient"
              testRows={companyPersonnelData} //TODO:開発用
              forceUpdate={clientPersonnelUpdateCount}
              headCells={getCompanyPersonnelListHead()}
              rowMapper={companyPersonnelDataMapper}
              listName="担当者"
              disablePagination
              editDialogContent={CompanyPersonnelForm}
            ></AddEditDialogTable>
          </Grid>
        </Grid>
      </DefaultPaper>

      {displayTargetPeriodList && (
        <DefaultPaper title={"対象期間一覧"}>
          <AddEditDialogTable
            getPath={`../../api/auditor/GetPeriodList?companyId=${params.id}`}
            deletePath="../../api/auditor/DeleteTargetPeriod"
            testRows={targetPeriodData}
            headCells={getTargetPeriodListHead()}
            listName={"対象期間"}
            editDialogContent={TargetPeriodForm}
            disablePagination
          ></AddEditDialogTable>
        </DefaultPaper>
      )}

      {/* 会社情報編集ダイアログ */}
      <FormDialog
        open={companyEditDialogOpen}
        title={"会社情報の編集"}
        onClose={() => setCompanyEditDialogOpen(false)}
        maxWidth="sm"
      >
        <CompanyForm
          closeHandler={handleCloseCompanyEdit}
          targetData={companyData}
          {...fetchResult}
          clientCode={fetchResult?.clientData?.companyCode}
        />
      </FormDialog>

      {/* クライアント担当者招待ダイアログ */}
      <Dialog maxWidth="xs" open={clientPersonnelInviteDialogOpen}>
        <DialogContent>
          クライアント担当者のアカウント発行を実行します。
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickInvitationConfirm}>Ok</Button>
          <Button autoFocus onClick={handleCancelInvitationConfirm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Step2以降 */}
      {/* 会社情報確認依頼ダイアログ */}
      {/* <Dialog
        open={companyInfoConfirmationRequestDialogOpen}
        onClose={handleCloseCompanyInfoConfirmationRequestDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle align="center">会社情報確認依頼</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={() => setRequestDialogOpen(true)}>
              情報確認依頼
            </Button>
            <Button onClick={() => setResponseDialogOpen(true)}>
              確認結果登録
            </Button>
          </DialogActions>
          <Card sx={{ paddingLeft: "1rem" }}>
            <AddEditDialogTable
              testRows={companyInfoConfirmationRequestData}
              headCells={getCompanyInfoConfirmationRequestListHead()}
              disablePagination
              disableAdd
              disableDelete
              disableEdit
              disableDialog
            ></AddEditDialogTable>
          </Card>
        </DialogContent>
      </Dialog> */}
      {/* 確認依頼メッセージダイアログ */}
      {/* <FormDialog
        open={requestDialogOpen}
        title={"会社情報確認依頼"}
        onClose={handleCloseRequest}
        maxWidth="sm"
        fullWidth
      >
        <CompanyInfoComfirmationRequestMessageForm
          closeHandler={handleCloseCompanyEdit}
        />
      </FormDialog> */}
      {/* 確認回答メッセージダイアログ */}
      {/* <FormDialog
        open={responseDialogOpen}
        title={"会社情報確認回答"}
        onClose={handleCloseResponse}
        maxWidth="sm"
        fullWidth
      >
        <CompanyInfoComfirmationResponseMessageForm
          closeHandler={handleCloseResponse}
        />
      </FormDialog> */}

      {/* 担当者一括編集ダイアログ */}
      {/* <FormDialog
        open={companyPersonnelSpreadsheetDialogOpen}
        title={"担当者情報の一括編集"}
        onClose={handleCloseCompanyPersonnelSpreadsheetDialog}
        fullWidth
        maxWidth="xl"
      >
        <CompanyPersonnelSpreadsheet
          closeHandler={handleCloseCompanyPersonnelSpreadsheetDialog}
        />
      </FormDialog> */}
    </>
  );
};

export default Company;
