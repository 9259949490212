/* eslint-disable react-hooks/exhaustive-deps */
import { Button, TextField } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";

// バリデーション定義
const schema = z.object({
  // teamId: z.any(),
  // teamName: z.string().min(1, { message: MESSAGES.REQUIRED }),
  // mail: z
  //   .string()
  //   .min(1, { message: MESSAGES.REQUIRED })
  //   .email({ message: MESSAGES.EMAIL_ERROR }),
  // teamMember: z.array(z.object({ value: z.string(), label: z.string() })),
  // smtpHost: z.string().min(1, { message: MESSAGES.REQUIRED }),
  // smtpPort: z.string().min(1, { message: MESSAGES.REQUIRED }),
  // smtpUser: z.string().min(1, { message: MESSAGES.REQUIRED }),
  // smtpPassword: z.string().min(1, { message: MESSAGES.REQUIRED }),
});

const DiffAdjustmentForm = ({ data = {}, closeHandler }) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();

  // API POST
  const onSubmit = async (data) => {
    await connect(() => {
      return httpClient.post("../api/mscsadmin/UpsertClient", data);
    }, "保存しました");
    console.log("post end", data);
    closeHandler();
  };

  return (
    <>
      <Controller
        name="cliendCode"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            label="メッセージ"
            multiline
            minRows={2}
            maxRows={6}
            fullWidth
            {...field}
            error={!!errors.cliendCode}
            helperText={errors.cliendCode ? errors.cliendCode.message : ""}
          />
        )}
      />
      <br />
      <Controller
        name="attachment"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            label="添付ファイル"
            type="file"
            inputProps={{ multiple: true }}
            {...field}
            error={!!errors.cliendCode}
            helperText={errors.cliendCode ? errors.cliendCode.message : ""}
          />
        )}
      />
      <br />
      <Button>送信</Button>
    </>
  );
};

export default DiffAdjustmentForm;
