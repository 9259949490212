/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from "@hookform/resolvers/zod";
import { Send } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";

// バリデーション定義
const schema = z.object({
  procedureId: z.string().optional(),
  confirmationLetterId: z.string().optional(),
  subject: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .max(
      Constants.ONE_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
    ),
  text: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .max(
      Constants.MULTI_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.MULTI_LINE_TEXT_MAX)
    ),
});
const MailForm = ({ procedureId, confirmationLetterId, closeHandler }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();

  // 新規作成時の初期化
  useEffect(() => {
    reset({
      procedureId: procedureId,
      confirmationLetterId: confirmationLetterId,
      subject: Constants.MAIL_MESSAGE.OWNED_STOCK.SUBJECT,
      text: Constants.MAIL_MESSAGE.OWNED_STOCK.TEXT,
    });
  }, []);

  // API POST
  const onSubmit = async (data) => {
    const result = await connect(() => {
      return httpClient.post("../../api/auditor/SendConfirmationLetters", data);
    }, "送信しました");
    // TODO:開発用
    console.log("post end", data);
    closeHandler(result);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="subject"
          control={control}
          render={({ field }) => (
            <TextField
              label="件名"
              fullWidth
              required
              {...field}
              error={!!errors.subject}
              helperText={errors.subject ? errors.subject.message : ""}
            />
          )}
        />
        <Controller
          name="text"
          control={control}
          render={({ field }) => (
            <TextField
              label="内容"
              fullWidth
              required
              multiline
              rows={6}
              {...field}
              error={!!errors.text}
              helperText={errors.text ? errors.text.message : ""}
            />
          )}
        />
        <Button type="submit" startIcon={<Send />}>
          送信
        </Button>
      </form>
    </>
  );
};

export default MailForm;
