import { Outlet } from "react-router-dom";
import Layout from "../../components/Layout";

function Index() {
  const headerLinkList = [
    {
      href: "/admin",
      text: "監査人一覧",
    },
    {
      href: "teamlist",
      text: "監査チーム一覧",
    },
  ];
  const settingLinkList = [
    {
      href: "companysettings",
      text: "監査法人の設定",
    },
    {
      href: "/auditor",
      text: "監査人画面へ戻る",
    },
  ];
  return (
    <Layout headerLinkList={headerLinkList}>
      {/* <ScrollRestoration /> */}
      <Outlet />
    </Layout>
  );
}

export default Index;
