const getHistoryListHead = () => {
  return [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "operationType",
      numeric: false,
      disablePadding: true,
      label: "操作種別",
      visible: true,
    },
    {
      id: "role",
      numeric: false,
      disablePadding: true,
      label: "ロール",
      visible: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "名前",
      visible: true,
    },
    {
      id: "dateTime",
      numeric: false,
      disablePadding: true,
      label: "日時",
      visible: true,
    },
  ];
};

function createData(id, operationType, role, name, dateTime) {
  return {
    id,
    operationType,
    role,
    name,
    dateTime,
  };
}

export const historyData = [
  createData("1", "編集", "監査人", "監査 太郎", "2024/03/31 12:00"),
  createData("2", "編集", "被監査企業", "被監査 次郎", "2024/03/31 12:00"),
  createData("3", "回収", "相手先", "相手先 三郎", "2024/03/31 12:00"),
];

export default getHistoryListHead;
