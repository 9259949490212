/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from "react-redux";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";
import MultiSelectInput from "../Input/MultiSelectInput";

// バリデーション定義
const schema = z.object({
  id: z.string(),
  companyId: z.string().min(1, MESSAGES.REQUIRED),
  targetPeriodId: z.string().min(1, MESSAGES.REQUIRED),
  referenceDate: z.string().min(1, MESSAGES.REQUIRED),
  procedureName: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .max(Constants.ONE_LINE_TEXT_MAX, MESSAGES.TEXT_MAX_ERROR),
  confirmationType: z.string().min(1, MESSAGES.REQUIRED),
  approvalType: z.string().min(1, MESSAGES.REQUIRED),
  auditorsInCharge: z.array(z.string()).nonempty(MESSAGES.REQUIRED),
});

const ProcedureForm = ({
  targetData = {},
  confirmationLetterTypeList,
  approvalTypeList,
  auditorList,
  companyList,
  targetPeriodId,
  closeHandler,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();
  const role = useSelector((state) => state.role.role);

  // 新規作成時にフォームをクリア
  const [isNew, setIsNew] = useState();
  const [disableEditApprovalType, setDisableEditApprovalType] = useState(false);
  useEffect(() => {
    if (Object.keys(targetData).length !== 0) {
      reset({
        ...targetData,
        referenceDate: targetData?.referenceDateFormatted?.replace(/\//g, "-"),
      });
      setIsNew(false);
      setDisableEditApprovalType(targetData.isAuthorityDetermined);
    } else {
      reset({
        id: "",
        companyId: "",
        targetPeriodId: targetPeriodId,
        referenceDate: "",
        procedureName: "",
        confirmationType: Constants.CONFIRMATION_LETTER_TYPE.OWNED_STOCK,
        approvalType: Constants.APPROVAL_TYPE.ALL_APPROVER,
        auditorsInCharge: [],
      });
      setIsNew(true);
    }
  }, [targetData]);

  // API POST
  const onSubmit = async (data) => {
    const result = await connect(() => {
      return httpClient.post("../../api/auditor/UpsertProcedure", data);
    }, "保存しました");
    // TODO:開発用
    console.log("post end", data);
    closeHandler(result);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ minWidth: "5rem" }}>
          <Controller
            name="companyId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="会社コード"
                select
                required
                disabled={!isNew || role === Constants.ROLE.CLIENT}
                {...field}
                error={!!errors.companyId}
                helperText={errors.companyId ? errors.companyId.message : ""}
              >
                {companyList?.map((row) => (
                  <MenuItem value={row.value} key={row.value}>
                    {row.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </FormControl>
        <br />
        <Controller
          name="referenceDate"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="基準日"
              required
              disabled={role === Constants.ROLE.CLIENT}
              type="date"
              {...field}
              error={!!errors.referenceDate}
              helperText={
                errors.referenceDate ? errors.referenceDate.message : ""
              }
            />
          )}
        />
        <br />
        <Controller
          name="procedureName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="確認手続名称"
              disabled={role === Constants.ROLE.CLIENT}
              required
              {...field}
              error={!!errors.procedureName}
              helperText={
                errors.procedureName ? errors.procedureName.message : ""
              }
            />
          )}
        />
        <br />
        <FormControl sx={{ minWidth: "5rem" }}>
          <Controller
            name="confirmationType"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="確認状様式"
                select
                required
                disabled={!isNew || role === Constants.ROLE.CLIENT}
                {...field}
                error={!!errors.confirmationType}
                helperText={
                  errors.confirmationType ? errors.confirmationType.message : ""
                }
              >
                {confirmationLetterTypeList?.map((row) => (
                  <MenuItem value={row.code} key={row.code}>
                    {row.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </FormControl>
        <br />
        <Controller
          name="approvalType"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="承認種別"
              select
              required
              disabled={
                disableEditApprovalType || role === Constants.ROLE.CLIENT
              }
              {...field}
              error={!!errors.approvalType}
              helperText={
                errors.approvalType ? errors.approvalType.message : ""
              }
            >
              {approvalTypeList?.map((row) => (
                <MenuItem value={row.code} key={row.code}>
                  {row.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <br />
        <Controller
          name="auditorsInCharge"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <MultiSelectInput
              disabled={role === Constants.ROLE.CLIENT}
              label="監査人担当者"
              items={auditorList?.map((auditor) => ({
                value: auditor.id,
                label: auditor.name,
              }))}
              required
              chipNewLine
              field={field}
              errors={errors}
            />
          )}
        />
        <br />
        {role === Constants.ROLE.AUDITOR && <Button type="submit">保存</Button>}
      </form>
    </>
  );
};

export default ProcedureForm;
