function createData(id, dateTime, target, content, name, status) {
  return {
    id,
    dateTime,
    target,
    content,
    name,
    status,
  };
}

export const contactData = [
  createData(
    "1",
    "2024/03/31 12:00",
    "HLB Meisei有限責任監査法人",
    "といあわせないよう\n内容ああああああああ",
    "確認先太郎",
    "未回答"
  ),
  createData(
    "2",
    "2024/03/31 12:00",
    "HLB Meisei有限責任監査法人",
    "といあわせないよう\n内容いいいいいいいいい",
    "確認先太郎",
    "回答済み"
  ),
];

const getContactListHead = () => {
  return [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "dateTime",
      numeric: false,
      disablePadding: true,
      label: "問合せ日時",
      visible: true,
    },
    {
      id: "target",
      numeric: false,
      disablePadding: true,
      label: "問合せ先",
      visible: true,
    },
    {
      id: "content",
      numeric: false,
      disablePadding: true,
      label: "問合せ内容",
      visible: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "氏名",
      visible: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "ステータス",
      visible: true,
    },
  ];
};
export default getContactListHead;
