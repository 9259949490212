import { Button } from "@mui/material";
import React, { useState } from "react";
import {
  DataSheetGrid,
  dateColumn,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import { FaFileExcel } from "react-icons/fa";
import selectColumn from "./Columns/SelectColumn";

// 手続一覧
const ProcedureSpreadsheet = ({ disabled = false }) => {
  function createConfirmationLetterData(
    id,
    code,
    name,
    type,
    language,
    responseDueDate,
    status
  ) {
    return { id, code, name, type, language, responseDueDate, status };
  }
  const rows = [
    {
      busyo: "部署1",
      name: "確認先　太郎",
      email: "mail@mail.com",
      role: "回答責任者",
    },
    {
      busyo: "部署1",
      name: "確認先　次郎",
      email: "jiro@mail.com",
      role: "回答作成者",
    },
  ];
  const [data, setData] = useState(rows);
  const columns = [
    {
      ...keyColumn("companyCode", textColumn),
      title: "会社コード",
      minWidth: 30,
      grow: 0.3,
    },
    {
      ...keyColumn("defaultReferenceDate", dateColumn),
      title: "基準日",
      minWidth: 120,
      grow: 0.3,
    },
    {
      ...keyColumn("procedureName", textColumn),
      title: "確認手続名称",
      minWidth: 50,
    },
    {
      ...keyColumn(
        "confirmationType",
        selectColumn({
          choices: [
            { value: "HOKKAIDO", label: "債権債務" },
            { value: "AOMORI", label: "弁護士" },
          ],
        })
      ),
      title: "確認状様式",
      minWidth: 50,
    },
    {
      ...keyColumn(
        "approvalType",
        selectColumn({
          choices: [
            { value: "COMPLETED", label: "全員必須" },
            { value: "UNCOMPLETED", label: "一人のみ" },
            { value: "UNCOMPLETED", label: "承認不要" },
          ],
        })
      ),
      title: "承認種別",
      minWidth: 50,
    },
  ];

  return (
    <>
      <Button startIcon={<FaFileExcel />}>CSフォーマットダウンロード</Button>
      <DataSheetGrid
        value={data}
        onChange={setData}
        columns={columns}
        gutterColumn={false}
        lockRows={disabled}
        autoAddRow
        height={300}
      />
    </>
  );
};

export default ProcedureSpreadsheet;
