const getUserListHead = () => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ユーザーID",
      visible: false,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "ユーザー名",
      visible: true,
    },
    {
      id: "furigana",
      numeric: false,
      disablePadding: true,
      label: "フリガナ",
      visible: true,
    },
    {
      id: "nameEnglish",
      numeric: false,
      disablePadding: true,
      label: "Name(English)",
      visible: true,
    },
    {
      id: "eMailAddress",
      numeric: false,
      disablePadding: true,
      label: "メールアドレス",
      visible: true,
    },
  ];
  return headCells;
};

// TODO:開発用
function createUserData(id, name, furigana, englishName, email, team) {
  return {
    id,
    name,
    furigana,
    englishName,
    email,
    team,
  };
}

// TODO:開発用
export const getUserRows = () => {
  return [
    createUserData(
      1,
      "監査人 太郎",
      "かんさにん　たろう",
      "Auditor Taro",
      "taro@hlbmeisei.jp",
      <>監査チーム1</>
    ),
    createUserData(
      2,
      "監査人 次郎",
      "かんさにん　じろう",
      "Auditor Jiro",
      "jiro@hlbmeisei.jp",
      <>
        監査チーム1
        <br />
        監査チーム2
      </>
    ),
  ];
};

export default getUserListHead;
