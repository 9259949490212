import { Typography } from "@mui/material";
import React from "react";
import DefaultPaper from "../../components/DefaultPaper";
import UserForm from "../../components/Form/UserForm";
import AddEditDialogTable from "../../components/Table/AddEditDialogTable";
import getUserListHead, { getUserRows } from "../../utils/UserListHead";

const UserList = ({ title }) => {
  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <DefaultPaper title="監査人一覧">
        <AddEditDialogTable
          getPath="../api/mscsadmin/GetAuditorList"
          deletePath="../api/mscsadmin/DeleteAuditor"
          testRows={getUserRows()} //TODO:開発用
          headCells={getUserListHead()}
          listName={"監査人"}
          editDialogContent={UserForm}
        ></AddEditDialogTable>
      </DefaultPaper>
    </>
  );
};
export default UserList;
