import { configureStore, createSlice } from "@reduxjs/toolkit";

// ローディング表示管理
const loadingSlice = createSlice({
  name: "loadingManager",
  initialState: { loadingArray: [] },
  reducers: {
    start(state, action) {
      state.loadingArray = [...state.loadingArray, action.payload];
    },
    end(state, action) {
      state.loadingArray = state?.loadingArray?.filter(
        (loading) => loading !== action.payload
      );
    },
  },
});

// メッセージトースト表示管理
const messageSlice = createSlice({
  name: "messageManager",
  initialState: { message: null, type: null },
  reducers: {
    setError(state, action) {
      state.message = action.payload;
      state.type = "error";
    },
    setSuccess(state, action) {
      state.message = action.payload;
      state.type = "success";
    },
    remove(state) {
      state.message = null;
      state.type = null;
    },
  },
});

// ロール管理(使用中のロール)
const roleSlice = createSlice({
  name: "roleManager",
  initialState: { role: null },
  reducers: {
    setRole(state, action) {
      state.role = action.payload;
    },
  },
});
// ロール管理(保持しているすべてのロール)
const rolesSlice = createSlice({
  name: "rolesManager",
  initialState: { roles: null },
  reducers: {
    setRoles(state, action) {
      state.roles = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    loading: loadingSlice.reducer,
    message: messageSlice.reducer,
    role: roleSlice.reducer,
    roles: rolesSlice.reducer,
  },
});

export default store;
export const loadingActions = loadingSlice.actions;
export const messageActions = messageSlice.actions;
export const roleActions = roleSlice.actions;
export const rolesActions = rolesSlice.actions;
