/* eslint-disable react-hooks/exhaustive-deps */
import { Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";

// バリデーション定義
const schema = z.object({
  referenceEntity: z.string(),
  referenceRecordId: z.string(),
  messageType: z.string(),
  messageClassification: z.string(),
  content: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .max(
      Constants.MULTI_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.MULTI_LINE_TEXT_MAX)
    ),
  senderRole: z.string(),
});

const MessageForm = ({ targetData = {}, closeHandler }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();

  // フォームの初期化
  useEffect(() => {
    reset({ ...targetData });
  }, [targetData]);

  // API POST
  const onSubmit = async (data) => {
    const result = await connect(() => {
      return httpClient.post("../../api/auditor/UpsertMessage", data);
    }, "送信しました");
    // TODO:削除
    console.log("post end", data);
    closeHandler(result);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="content"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="メッセージ"
              required
              multiline
              minRows={2}
              maxRows={6}
              fullWidth
              {...field}
              error={!!errors.content}
              helperText={errors.content ? errors.content.message : ""}
            />
          )}
        />
        <br />
        <Button type="submit">送信</Button>
      </form>
    </>
  );
};

export default MessageForm;
