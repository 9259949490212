import { Box, Chip, FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selected, theme) {
  return {
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultiSelectInput({
  label,
  name,
  items,
  field,
  errors,
  chipNewLine = false,
  required = false,
  disabled = false,
  sx,
}) {
  const theme = useTheme();
  // const [selected, setSelected] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    field.onChange(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  //選択解除処理
  const chipDelete = (name) => {
    field.onChange(field.value.filter((value) => value !== name));
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel required={required} disabled={disabled}>
          {label}
        </InputLabel>
        <Select
          multiple
          disabled={disabled}
          {...field}
          onChange={handleChange}
          error={!!errors[name]}
          inputProps={{ helperText: errors[name] ? errors[name].message : "" }}
          input={<OutlinedInput />}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                maxWidth: chipNewLine ? "12rem" : null,
              }}
            >
              {selected?.map((value) => (
                <>
                  <Chip
                    disabled={disabled}
                    key={value}
                    label={items?.find((item) => item.value === value)?.label}
                    onDelete={(event) => chipDelete(value)}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                  />
                </>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          sx={sx}
        >
          {items?.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              style={getStyles(item.value, field.value, theme)}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {!!errors[name] && (
          <FormHelperText error>{errors[name].message}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}
