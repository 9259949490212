export default function UnauthorizedPage() {
  const previousPage = document.referrer;
  window.location.href = `/.auth/login/aad?post_login_redirect_uri=${getPathFromUrl(
    previousPage
  )}`;
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>Unauthorized</i>
      </p>
    </div>
  );
}

function getPathFromUrl(url) {
  const match = url.match(/https?:\/\/[^/]+(.*)/);
  return match ? match[1] : "/";
}
