import React, { useEffect, useState } from "react";
import {
  DynamicDataSheetGrid,
  floatColumn,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import { Controller } from "react-hook-form";
import Constants from "../../../utils/Constants";
import { differenceChoices } from "../Columns/DifferenceChoices";
import selectColumn from "../Columns/SelectColumn";

// 所有株式
const OwnedStockSpreadsheet = ({
  disableConfirmation,
  disableResponse,
  confirmationLetterId,
  presenceOrAbsenceList,
  control,
}) => {
  // ドロップダウンの初期化
  const [presenceOrAbsenceChoices, setPresenceOrAbsenceChoices] = useState([]);
  useEffect(
    () => {
      setPresenceOrAbsenceChoices(
        presenceOrAbsenceList?.map((type) => {
          return { value: type.code, label: type.name };
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [presenceOrAbsenceList]
  );

  // ヘッダー定義
  const columns = [
    {
      ...keyColumn("details1", textColumn),
      title: "株式の種類",
      disabled: disableConfirmation,
    },
    {
      ...keyColumn("details2", textColumn),
      title: "証券名",
      disabled: disableConfirmation,
    },
    {
      ...keyColumn("volume", floatColumn),
      title: "数量等",
      disabled: disableConfirmation,
    },
    {
      ...keyColumn("dummy", textColumn),
      title: "ー",
      disabled: true,
    },
    {
      ...keyColumn("dummy2", textColumn),
      title: "ー",
      disabled: true,
    },
    {
      ...keyColumn(
        "differencesExistence",
        selectColumn({
          choices: differenceChoices,
          disabled: disableResponse,
        })
      ),
      title: "相違の有無",
      disabled: disableResponse,
      grow: 0.5,
      deleteValue: () => null,
      copyValue: ({ rowData }) =>
        differenceChoices?.find((choice) => choice.value === rowData?.authority)
          ?.label,
      pasteValue: ({ rowData, value }) => ({
        ...rowData,
        authority:
          differenceChoices?.find((choice) => choice.label === value)?.value ??
          null,
      }),
    },
    {
      ...keyColumn("volume3", floatColumn),
      title: "発行済株式数",
      disabled: disableResponse,
      grow: 0.8,
    },
    {
      ...keyColumn(
        "details3",
        selectColumn({
          choices: presenceOrAbsenceChoices,
          disabled: disableResponse,
        })
      ),
      title: "質権設定の有無",
      disabled: disableResponse,
      grow: 0.5,
      deleteValue: () => null,
      copyValue: ({ rowData }) =>
        presenceOrAbsenceChoices?.find(
          (choice) => choice.value === rowData?.authority
        )?.label,
      pasteValue: ({ rowData, value }) => ({
        ...rowData,
        authority:
          presenceOrAbsenceChoices?.find((choice) => choice.label === value)
            ?.value ?? null,
      }),
    },
    {
      ...keyColumn("details4", textColumn),
      title: "質権者の氏名\n又は名称",
      disabled: disableResponse,
    },
    {
      ...keyColumn("details5", textColumn),
      title: "質権の内容",
      disabled: disableResponse,
      grow: 2,
    },
    {
      ...keyColumn(
        "details6",
        selectColumn({
          choices: presenceOrAbsenceChoices,
          disabled: disableResponse,
        })
      ),
      title: "株券発行の有無",
      minwidth: "5rem",
      disabled: disableResponse,

      deleteValue: () => null,
      copyValue: ({ rowData }) =>
        presenceOrAbsenceChoices?.find(
          (choice) => choice.value === rowData?.authority
        )?.label,
      pasteValue: ({ rowData, value }) => ({
        ...rowData,
        authority:
          presenceOrAbsenceChoices?.find((choice) => choice.label === value)
            ?.value ?? null,
      }),
    },
  ];

  return (
    <>
      <Controller
        name="ownedStock"
        control={control}
        render={({ field }) => (
          <DynamicDataSheetGrid
            onChange={field.onChange}
            value={field.value}
            columns={columns}
            lockRows={disableConfirmation}
            createRow={() => ({
              id: "",
              confirmationLetterId: confirmationLetterId,
              kubun: Constants.OWNED_STOCK_KUBUN.OWNED_STOCK,
            })}
            duplicateRow={(rowData) => ({
              ...rowData,
              id: "",
            })}
          />
        )}
      />
    </>
  );
};

export default OwnedStockSpreadsheet;
