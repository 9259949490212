const getTeamListHead = () => {
  const headCells = [
    {
      id: "teamId",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "teamName",
      numeric: false,
      disablePadding: true,
      label: "チーム名",
      visible: true,
    },
    {
      id: "mail",
      numeric: false,
      disablePadding: true,
      label: "メールアドレス",
      visible: true,
    },
  ];
  return headCells;
};

function createTeamData(
  teamId,
  teamName,
  mail,
  smtpHost,
  smtpPort,
  smtpUser,
  smtpPassword
) {
  return {
    teamId,
    teamName,
    mail,
    smtpHost,
    smtpPort,
    smtpUser,
    smtpPassword,
  };
}

export const getTeamRows = () => {
  return [
    createTeamData(
      "01J1HXHAJB8X0WYETQ7H1BM7CE",
      "監査チームA",
      "team_a@hlbmeisei.jp"
    ),
    createTeamData(
      "01J1HXHAJCMYB41BVVEA0ZYPJ7",
      "監査チームB",
      "team_b@hlbmeisei.jp"
    ),
    createTeamData(
      "01J1HXHAJCE2C9J2XNS1CR4M59",
      "監査チームC",
      "team_c@hlbmeisei.jp"
    ),
    createTeamData(
      "01J1HXHAJCXZ0B79B0JPEMCQ3D",
      "監査チームD",
      "team_d@hlbmeisei.jp"
    ),
    createTeamData(
      "01J1HXHAJC25HY4QW0Y1Z8NWBZ",
      "監査チームE",
      "team_e@hlbmeisei.jp"
    ),
  ];
};

export default getTeamListHead;
