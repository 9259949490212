import { zodResolver } from "@hookform/resolvers/zod";
import { Button, FormHelperText, InputLabel, TextField } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";
import CorporateBondSpreadsheet from "../SpreadSheets/OwnedStock/CorporateBondSpreadsheet";
import EquitySpreadsheet from "../SpreadSheets/OwnedStock/EquitySpreadsheet";
import OtherStockSpreadsheet from "../SpreadSheets/OwnedStock/OtherStockSpreadsheet";
import OwnedStockSpreadsheet from "../SpreadSheets/OwnedStock/OwnedStockSpreadsheet";
import StockAcquisitionRightsSpreadsheet from "../SpreadSheets/OwnedStock/StockAcquisitionRightsSpreadsheet";

// バリデーション定義
const schema = z.object({
  id: z.string(),
  requestStatement: z
    .string()
    .max(
      Constants.MULTI_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.MULTI_LINE_TEXT_MAX)
    )
    .optional(),
  ownedStock: z
    .array(
      z
        .object({
          id: z.string(),
          details1: z
            .string({
              message: MESSAGES.REQUIRED_ERROR_WITH_NAME("株式の種類"),
            })
            .min(1, MESSAGES.REQUIRED_ERROR_WITH_NAME("株式の種類"))
            .max(
              Constants.ONE_LINE_TEXT_MAX,
              MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
            ),
        })
        .passthrough()
    )
    .optional(),
  equity: z
    .array(
      z
        .object({
          id: z.string(),
          details1: z
            .string({
              message: MESSAGES.REQUIRED_ERROR_WITH_NAME("出資の種類"),
            })
            .min(1, MESSAGES.REQUIRED_ERROR_WITH_NAME("出資の種類"))
            .max(
              Constants.ONE_LINE_TEXT_MAX,
              MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
            ),
        })
        .passthrough()
    )
    .optional(),
  corporateBond: z
    .array(
      z
        .object({
          id: z.string(),
          details1: z
            .string({
              message: MESSAGES.REQUIRED_ERROR_WITH_NAME("社債の種類及び名称"),
            })
            .min(1, MESSAGES.REQUIRED_ERROR_WITH_NAME("社債の種類及び名称"))
            .max(
              Constants.ONE_LINE_TEXT_MAX,
              MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
            ),
        })
        .passthrough()
    )
    .optional(),
  stockAcquisittonRights: z
    .array(
      z
        .object({
          id: z.string(),
          details1: z
            .string({
              message: MESSAGES.REQUIRED_ERROR_WITH_NAME("種類及び名称"),
            })
            .min(1, MESSAGES.REQUIRED_ERROR_WITH_NAME("種類及び名称"))
            .max(
              Constants.ONE_LINE_TEXT_MAX,
              MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
            ),
        })
        .passthrough()
    )
    .optional(),
  otherStock: z
    .array(
      z
        .object({
          id: z.string(),
          details1: z
            .string({
              message: MESSAGES.REQUIRED_ERROR_WITH_NAME("種類"),
            })
            .min(1, MESSAGES.REQUIRED_ERROR_WITH_NAME("種類"))
            .max(
              Constants.ONE_LINE_TEXT_MAX,
              MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
            ),
        })
        .passthrough()
    )
    .optional(),
  differences: z
    .string()
    .max(
      Constants.MULTI_LINE_TEXT_MAX,
      MESSAGES.TEXT_MAX_ERROR(Constants.MULTI_LINE_TEXT_MAX)
    )
    .optional(),
});

const OwnedStockConfirmation = ({
  targetData,
  presenceOrAbsenceList,
  postSaveHandler = () => {},
  readOnly = false,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });
  const role = useSelector((state) => state.role.role);
  const { connect } = useApi();
  const params = useParams();

  // 初期化
  useEffect(
    () => {
      reset({
        ...targetData,
        ownedStock: targetData?.confirmationDetails?.filter(
          (row) => row.kubun === Constants.OWNED_STOCK_KUBUN.OWNED_STOCK
        ),
        equity: targetData?.confirmationDetails?.filter(
          (row) => row.kubun === Constants.OWNED_STOCK_KUBUN.EQUITY
        ),
        corporateBond: targetData?.confirmationDetails?.filter(
          (row) => row.kubun === Constants.OWNED_STOCK_KUBUN.CORPORATE_BOND
        ),
        stockAcquisittonRights: targetData?.confirmationDetails?.filter(
          (row) =>
            row.kubun === Constants.OWNED_STOCK_KUBUN.STOCK_ACQUISITION_RIGHTS
        ),
        otherStock: targetData?.confirmationDetails?.filter(
          (row) => row.kubun === Constants.OWNED_STOCK_KUBUN.OTHER_STOCK
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetData]
  );

  // API POST
  const onSubmit = async (data) => {
    // 確認詳細データの整形
    data.confirmationDetails = [];
    if (data.ownedStock?.length) {
      data.confirmationDetails.push(...data.ownedStock);
    }
    if (data.equity?.length) {
      data.confirmationDetails.push(...data.equity);
    }
    if (data.corporateBond?.length) {
      data.confirmationDetails.push(...data.corporateBond);
    }
    if (data.stockAcquisittonRights?.length) {
      data.confirmationDetails.push(...data.stockAcquisittonRights);
    }
    if (data.otherStock?.length) {
      data.confirmationDetails.push(...data.otherStock);
    }
    const result = await connect(() => {
      return httpClient.post(`/api/${role}/UpsertConfirmation`, data);
    }, "保存しました");
    if (result?.status === 200) {
      postSaveHandler();
    }
    console.log("post end", data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {role !== Constants.ROLE.CONFIRMER && (
          <Controller
            name="requestStatement"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="確認依頼文"
                {...field}
                multiline
                fullWidth
                disabled={role === Constants.ROLE.CLIENT || readOnly}
                minRows={2}
                sx={{ marginBottom: "1rem" }}
              />
            )}
          />
        )}
        <InputLabel>(1)株式</InputLabel>
        <OwnedStockSpreadsheet
          disableConfirmation={
            targetData?.confirmationLetterStatus !==
            Constants.CONFIRMATION_LETTER_STATUS.REGISTERED
          }
          disableResponse={
            role === Constants.ROLE.CLIENT ||
            (role === Constants.ROLE.AUITOR &&
              targetData?.sendingMethod ===
                Constants.SENDING_METHOD.EMAIL_OR_POST) ||
            (role === Constants.ROLE.CONFIRMER &&
              params.type !== "create" &&
              targetData?.confirmerAuthority ===
                Constants.CONFIRMER_AUTHORITY_TYPE.APPROVAL) ||
            readOnly
          }
          confirmationLetterId={targetData?.id}
          targetData={targetData?.ownedStock}
          presenceOrAbsenceList={presenceOrAbsenceList}
          control={control}
          setValue={setValue}
        />
        <FormHelperText className="Mui-error">
          {errors?.ownedStock?.map((row) => {
            let errorFields = Object.keys(row);
            return (
              <>
                {errorFields?.map((key) => (
                  <>
                    {row[key]?.message}
                    <br />
                  </>
                ))}
              </>
            );
          })}
        </FormHelperText>
        <br />
        <InputLabel>(2)持分</InputLabel>
        <EquitySpreadsheet
          disableConfirmation={
            targetData?.confirmationLetterStatus !==
            Constants.CONFIRMATION_LETTER_STATUS.REGISTERED
          }
          disableResponse={
            role === Constants.ROLE.CLIENT ||
            (role === Constants.ROLE.AUITOR &&
              targetData?.sendingMethod ===
                Constants.SENDING_METHOD.EMAIL_OR_POST) ||
            (role === Constants.ROLE.CONFIRMER &&
              params.type !== "create" &&
              targetData?.confirmerAuthority ===
                Constants.CONFIRMER_AUTHORITY_TYPE.APPROVAL) ||
            readOnly
          }
          confirmationLetterId={targetData?.id}
          targetData={targetData?.ownedStock}
          presenceOrAbsenceList={presenceOrAbsenceList}
          control={control}
          setValue={setValue}
        />
        <FormHelperText className="Mui-error">
          {errors?.equity?.map((row) => {
            let errorFields = Object.keys(row);
            return (
              <>
                {errorFields?.map((key) => (
                  <>
                    {row[key]?.message}
                    <br />
                  </>
                ))}
              </>
            );
          })}
        </FormHelperText>
        <br />
        <InputLabel>(3)社債</InputLabel>
        <CorporateBondSpreadsheet
          disableConfirmation={
            targetData?.confirmationLetterStatus !==
            Constants.CONFIRMATION_LETTER_STATUS.REGISTERED
          }
          disableResponse={
            role === Constants.ROLE.CLIENT ||
            (role === Constants.ROLE.AUITOR &&
              targetData?.sendingMethod ===
                Constants.SENDING_METHOD.EMAIL_OR_POST) ||
            (role === Constants.ROLE.CONFIRMER &&
              params.type !== "create" &&
              targetData?.confirmerAuthority ===
                Constants.CONFIRMER_AUTHORITY_TYPE.APPROVAL) ||
            readOnly
          }
          confirmationLetterId={targetData?.id}
          targetData={targetData?.ownedStock}
          presenceOrAbsenceList={presenceOrAbsenceList}
          control={control}
          setValue={setValue}
        />
        <FormHelperText className="Mui-error">
          {errors?.corporateBond?.map((row) => {
            let errorFields = Object.keys(row);
            return (
              <>
                {errorFields?.map((key) => (
                  <>
                    {row[key]?.message}
                    <br />
                  </>
                ))}
              </>
            );
          })}
        </FormHelperText>
        <br />
        <InputLabel>(4)新株予約権等</InputLabel>
        <StockAcquisitionRightsSpreadsheet
          disableConfirmation={
            targetData?.confirmationLetterStatus !==
            Constants.CONFIRMATION_LETTER_STATUS.REGISTERED
          }
          disableResponse={
            role === Constants.ROLE.CLIENT ||
            (role === Constants.ROLE.AUITOR &&
              targetData?.sendingMethod ===
                Constants.SENDING_METHOD.EMAIL_OR_POST) ||
            (role === Constants.ROLE.CONFIRMER &&
              params.type !== "create" &&
              targetData?.confirmerAuthority ===
                Constants.CONFIRMER_AUTHORITY_TYPE.APPROVAL) ||
            readOnly
          }
          confirmationLetterId={targetData?.id}
          // targetData={targetData?.ownedStock}
          presenceOrAbsenceList={presenceOrAbsenceList}
          control={control}
          setValue={setValue}
        />
        <FormHelperText className="Mui-error">
          {errors?.stockAcquisittonRights?.map((row) => {
            let errorFields = Object.keys(row);
            return (
              <>
                {errorFields?.map((key) => (
                  <>
                    {row[key]?.message}
                    <br />
                  </>
                ))}
              </>
            );
          })}
        </FormHelperText>
        <br />
        <InputLabel>(5)その他</InputLabel>
        <OtherStockSpreadsheet
          disableConfirmation={
            targetData?.confirmationLetterStatus !==
            Constants.CONFIRMATION_LETTER_STATUS.REGISTERED
          }
          disableResponse={
            role === Constants.ROLE.CLIENT ||
            (role === Constants.ROLE.AUITOR &&
              targetData?.sendingMethod ===
                Constants.SENDING_METHOD.EMAIL_OR_POST) ||
            (role === Constants.ROLE.CONFIRMER &&
              params.type !== "create" &&
              targetData?.confirmerAuthority ===
                Constants.CONFIRMER_AUTHORITY_TYPE.APPROVAL) ||
            readOnly
          }
          confirmationLetterId={targetData?.id}
          targetData={targetData?.ownedStock}
          presenceOrAbsenceList={presenceOrAbsenceList}
          control={control}
          setValue={setValue}
        />
        <FormHelperText className="Mui-error">
          {errors?.otherStock?.map((row) => {
            let errorFields = Object.keys(row);
            return (
              <>
                {errorFields?.map((key) => (
                  <>
                    {row[key]?.message}
                    <br />
                  </>
                ))}
              </>
            );
          })}
        </FormHelperText>

        <Controller
          name="differences"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="相違内容"
              {...field}
              multiline
              fullWidth
              disabled={
                role === Constants.ROLE.CLIENT ||
                (role === Constants.ROLE.AUITOR &&
                  targetData?.sendingMethod ===
                    Constants.SENDING_METHOD.EMAIL_OR_POST) ||
                (role === Constants.ROLE.CONFIRMER &&
                  params.type !== "create" &&
                  targetData?.confirmerAuthority ===
                    Constants.CONFIRMER_AUTHORITY_TYPE.APPROVAL) ||
                readOnly
              }
              minRows={2}
              sx={{ marginTop: "1rem" }}
            />
          )}
        />
        {!readOnly && <Button type="submit">保存</Button>}
      </form>
    </>
  );
};

export default OwnedStockConfirmation;
