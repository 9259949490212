import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import "react-datasheet-grid/dist/style.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import AdminCompanysettings from "./routes/admin/Companysettings";
import Admin from "./routes/admin/index";
import AdminTeamList from "./routes/admin/TeamList";
import AdminUserList from "./routes/admin/UserList";
import Company from "./routes/auditor/Company";
import ConfirmationLetter from "./routes/auditor/ConfirmationLetter";
import Dashboard from "./routes/auditor/Dashboard";
import Auditor from "./routes/auditor/index";
import Procedure from "./routes/auditor/Procedure";
import ClientIndex from "./routes/client/index";
import ConfirmingConfirmationLetter from "./routes/confirmer/ConfirmationLetter";
import Confirming from "./routes/confirmer/index";
import ErrorPage from "./routes/ErrorPage";
import Index from "./routes/index";
import UnauthorizedPage from "./routes/Unauthorized";
import Constants from "./utils/Constants";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Index />} errorElement={<ErrorPage />}>
      {/* 監査人 */}
      <Route path={Constants.ROLE.AUDITOR} element={<Auditor />}>
        <Route index element={<Dashboard />} />
        <Route
          path="company/:id"
          element={<Company title="会社詳細" />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="procedure/:id"
          element={<Procedure title="確認手続きの詳細" />}
        />
        <Route
          path="confirmationLetter/:id"
          element={<ConfirmationLetter title="確認状の詳細" />}
        />
      </Route>
      {/* クライアント */}
      <Route path={Constants.ROLE.CLIENT} element={<ClientIndex />}>
        <Route index element={<Dashboard />} />
        <Route path="company/:id" element={<Company title="会社詳細" />} />
        <Route
          path="procedure/:id"
          element={<Procedure title="確認手続きの詳細" />}
        />
        <Route
          path="confirmationletter/:id"
          element={<ConfirmationLetter title="確認状の詳細" />}
        />
      </Route>
      {/* 確認先 */}
      <Route path={Constants.ROLE.CONFIRMER} element={<Confirming />}>
        <Route
          path="confirmationLetter/:type/:id"
          element={<ConfirmingConfirmationLetter title="確認状の詳細" />}
        />
      </Route>
      {/* 監理者 */}
      <Route path={Constants.ROLE.ADMIN} element={<Admin />}>
        <Route index element={<AdminUserList />} />
        <Route path="teamlist" element={<AdminTeamList />} />
        <Route
          path="companysettings"
          element={<AdminCompanysettings title="監査法人の設定" />}
        />
      </Route>
      <Route path="unauthorized" element={<UnauthorizedPage />} />
    </Route>
  )
);

function App() {
  return (
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={router}>
          {/* TODO:スクロール制御確認 */}
          {/* <ScrollRestoration /> */}
        </RouterProvider>
      </LocalizationProvider>
    </React.StrictMode>
  );
}

export default App;
