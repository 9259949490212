import { Outlet } from "react-router-dom";
import Layout from "../../components/Layout";

function Index() {
  return (
    <>
      <Layout>
        {/* <ScrollRestoration /> */}
        <Outlet />
      </Layout>
    </>
  );
}
export default Index;
