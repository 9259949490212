/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";

// バリデーション定義
const schema = z.object({
  id: z.string(),
  title: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .max(Constants.ONE_LINE_TEXT_MAX, MESSAGES.TEXT_MAX_ERROR),
  doCopy: z.boolean(),
  copyId: z.string(),
  companyId: z.string().min(1),
});

const TargetPeriodForm = ({ targetData = {}, data, closeHandler }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });
  const params = useParams();
  const { connect } = useApi();

  // 対象期間のコピー関連
  const [copy, setCopy] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);

  // 新規作成時にフォームをクリア
  useEffect(() => {
    if (Object.keys(targetData).length !== 0) {
      reset({ ...targetData });
      setIsCreateMode(false);
    } else {
      reset({
        id: "",
        title: "",
        doCopy: false,
        copyId: "",
        companyId: params.id,
      });
      setIsCreateMode(true);
    }
  }, [targetData]);

  // API POST
  const onSubmit = async (data) => {
    const result = await connect(() => {
      return httpClient.post("../../api/auditor/UpsertPeriod", data);
    }, "保存しました");
    console.log("post end", data);
    closeHandler(result);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="title"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="対象期間名"
              required
              {...field}
              error={!!errors.title}
              helperText={errors.title ? errors.title.message : ""}
            />
          )}
        />
        {false && isCreateMode && (
          <>
            <br />
            <Controller
              name="doCopy"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={copy}
                      onChange={(e) => console.log(setCopy(e.target.checked))}
                    />
                  }
                  label="既存の対象期間からコピーして新規作成する"
                />
              )}
            />
            {copy && (
              <>
                <br />
                <FormControl sx={{ minWidth: "5rem", paddingLeft: "3rem" }}>
                  <InputLabel>コピーする対象期間</InputLabel>
                  <Controller
                    name="copyId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select {...field}>
                        {data.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.title}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </>
            )}
          </>
        )}
        <br />
        <Button type="submit">保存</Button>
      </form>
    </>
  );
};

export default TargetPeriodForm;
