import { Typography } from "@mui/material";
import React from "react";
import DefaultPaper from "../../components/DefaultPaper";
import TeamForm from "../../components/Form/TeamForm";
import AddEditDialogTable from "../../components/Table/AddEditDialogTable";
import getTeamListHead, { getTeamRows } from "../../utils/TeamListHead";

const TeamList = ({ title }) => {
  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <DefaultPaper title="監査チーム一覧">
        <AddEditDialogTable
          getPath="../api/mscsadmin/GetAuditTeamList"
          deletePath="../api/mscsadmin/DeleteAuditTeam"
          keyColumnName="teamId"
          headCells={getTeamListHead()}
          listName="監査チーム"
          editDialogContent={TeamForm}
          // TODO:開発用
          testRows={getTeamRows}
        ></AddEditDialogTable>
      </DefaultPaper>
    </>
  );
};
export default TeamList;
