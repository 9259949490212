import { DoneRounded } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const getCompanyPersonnelListHead = () => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "userId",
      numeric: false,
      disablePadding: true,
      label: "担当者ID",
      visible: false,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "担当者名",
      visible: true,
    },
    {
      id: "eMailAddress",
      numeric: false,
      disablePadding: true,
      label: "メールアドレス",
      visible: true,
    },
    {
      id: "tel",
      numeric: false,
      disablePadding: true,
      label: "TEL",
      visible: true,
    },
    {
      id: "companyNameBelonging",
      numeric: false,
      disablePadding: true,
      label: "所属会社",
      visible: true,
    },
    {
      id: "isResponsibleTitle",
      numeric: false,
      disablePadding: true,
      label: "責任者",
      visible: true,
    },
    {
      id: "valid",
      numeric: false,
      disablePadding: true,
      label: "アカウント発行",
      visible: true,
    },
  ];
  return headCells;
};

export const companyPersonnelDataMapper = (data) => {
  return data?.data?.map((row) => ({
    ...row,
    companyNameBelonging: data?.clientGroupCompany.filter(
      (company) => company.id === row.companyIdBelonging
    )[0].companyName,
    valid:
      row.userStatus === "VALID" ? (
        <Tooltip title="済">
          <DoneRounded />
        </Tooltip>
      ) : (
        <></>
      ),
    isResponsibleTitle: row.isResponsible ? "はい" : "いいえ",
  }));
};

// TODO：開発用
function createData(
  id,
  personnelId,
  name,
  email,
  tel,
  company,
  companyId,
  responsible,
  valid
) {
  return {
    id,
    personnelId,
    name,
    email,
    tel,
    company,
    companyId,
    responsible,
    valid,
  };
}

// TODO：開発用
export const companyPersonnelData = [
  createData(
    "1",
    "1",
    "山田　太郎",
    "mail@mail.com",
    "090-1234-5678",
    "株式会社AAA",
    "01",
    "はい",
    <Tooltip title="済">
      <DoneRounded />
    </Tooltip>
  ),
  createData(
    "2",
    "2",
    "山田　太郎",
    "mail@mail.com",
    "090-1234-5678",
    "株式会社AAA",
    "01",
    "いいえ",
    false
  ),
  createData(
    "3",
    "3",
    "山田　太郎",
    "mail@mail.com",
    "090-1234-5678",
    "株式会社AAA",
    "01",
    "はい",
    <Tooltip title="済">
      <DoneRounded />
    </Tooltip>
  ),
  createData(
    "4",
    "4",
    "山田　太郎",
    "mail@mail.com",
    "090-1234-5678",
    "株式会社AAA",
    "01",
    "いいえ",
    <Tooltip title="済">
      <DoneRounded />
    </Tooltip>
  ),
  createData(
    "5",
    "5",
    "山田　太郎",
    "mail@mail.com",
    "090-1234-5678",
    "株式会社AAA",
    "01",
    "いいえ",
    false
  ),
  createData(
    "6",
    "6",
    "山田　太郎",
    "mail@mail.com",
    "090-1234-5678",
    "株式会社AAA",
    "01",
    "いいえ",
    false
  ),
];
export default getCompanyPersonnelListHead;
