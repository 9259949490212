import { Link } from "react-router-dom";

const getDiffAdjustmentListHead = () => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "message",
      numeric: false,
      disablePadding: true,
      label: "メッセージ",
      visible: true,
    },
    {
      id: "attachment",
      numeric: false,
      disablePadding: true,
      label: "添付ファイル",
      visible: true,
    },
    {
      id: "role",
      numeric: false,
      disablePadding: true,
      label: "役割",
      visible: true,
      minWidth: "5rem",
    },
    {
      id: "personnel",
      numeric: false,
      disablePadding: true,
      label: "担当者",
      visible: true,
      minWidth: "5rem",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: "日時",
      visible: true,
      minWidth: "5rem",
    },
  ];
  return headCells;
};
// TODO：開発用
function createData(
  id,
  message,
  attachment,
  role,
  personnel,
  date,
  disableEditDelete
) {
  return { id, message, attachment, role, personnel, date, disableEditDelete };
}
// TODO:開発用
export const diffAdjustmentData = [
  createData(
    "AA1",
    "差異調整手続をお願いします。",
    null,
    "監査人",
    "監査 太郎",
    "2024/07/31 13:00"
  ),
  createData(
    "AA2",
    "コメント",
    <Link href="#" download>
      添付ファイル
    </Link>,
    "被監査企業",
    "被監査企業 太郎",
    "2024/07/31 16:00",
    true
  ),
];
export default getDiffAdjustmentListHead;
