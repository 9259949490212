import { Outlet } from "react-router-dom";
import Layout from "../../components/Layout";

function Index() {
  const headerLinkList = [
    {
      href: "company/new",
      text: "クライアントの新規作成",
    },
  ];
  const settingLinkList = [
    {
      href: "usersettings",
      text: "ユーザー個別設定",
    },
    {
      href: "/admin",
      text: "管理画面",
    },
  ];

  return (
    <Layout headerLinkList={headerLinkList}>
      <Outlet />
    </Layout>
  );
}

export default Index;
