const getTargetPeriodListHead = () => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "対象期間名",
      visible: true,
    },
  ];
  return headCells;
};
// TODO：開発用
function createData(id, targetPeriodId, name) {
  return {
    id,
    targetPeriodId,
    name,
  };
}

// TODO：開発用
export const targetPeriodData = [
  createData("1", "1", "2024年4月期"),
  createData("2", "2", "2023年4月期"),
  createData("3", "3", "2022年4月期"),
];
export default getTargetPeriodListHead;
