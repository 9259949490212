import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const DefaultPaper = ({ title, children }) => {
  return (
    <>
      <Paper sx={{ marginBottom: "0.5rem", padding: "1%" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">{title}</Typography>
          </Grid>
          <Divider />
          <Grid item xs={0.5} />
          <Grid item xs={11.5}>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
export default DefaultPaper;
