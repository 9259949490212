import { zodResolver } from "@hookform/resolvers/zod";
import { Button, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DynamicDataSheetGrid,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";
import selectColumn from "./Columns/SelectColumn";

// バリデーション定義
const schema = z.object({
  confirmerAuthority: z.array(
    z
      .object({
        id: z.string(),
        confirmationLetterId: z.string(),
        department: z
          .string()
          .max(
            Constants.ONE_LINE_TEXT_MAX,
            MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
          )
          .optional(),
        name: z
          .string({ message: MESSAGES.REQUIRED_ERROR_WITH_NAME("名前") })
          .min(1, { message: MESSAGES.REQUIRED_ERROR_WITH_NAME("名前") })
          .max(
            Constants.ONE_LINE_TEXT_MAX,
            MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
          ),
        eMailAddress: z
          .string({ message: MESSAGES.REQUIRED_ERROR_WITH_NAME("名前") })
          .min(1, {
            message: MESSAGES.REQUIRED_ERROR_WITH_NAME("メールアドレス"),
          })
          .max(
            Constants.ONE_LINE_TEXT_MAX,
            MESSAGES.TEXT_MAX_ERROR(Constants.ONE_LINE_TEXT_MAX)
          )
          .email({ message: MESSAGES.EMAIL_ERROR }),
        authority: z
          .string({ message: MESSAGES.REQUIRED_ERROR_WITH_NAME("名前") })
          .min(1, MESSAGES.REQUIRED_ERROR_WITH_NAME("担当者種別")),
        userId: z.string().optional(),
      })
      .optional()
  ),
});

// 確認先担当者一覧
const ConfirmerSpreadsheet = ({
  targetData,
  confirmationLetterId,
  confirmerAuthorityTypeList,
  postSaveHandler = () => {},
  disabled = false,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();

  // 初期化
  useEffect(
    () => {
      setValue("confirmerAuthority", targetData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetData]
  );
  const [authorityChoices, setAuthorityChoices] = useState([]);
  // ドロップダウンの初期化
  useEffect(
    () => {
      setAuthorityChoices(
        confirmerAuthorityTypeList?.map((type) => {
          return { value: type.code, label: type.name };
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmerAuthorityTypeList]
  );

  // API POST
  const onSubmit = async (data) => {
    await connect(() => {
      return httpClient.post(
        `../../api/auditor/UpsertConfirmerBulk?confirmationLetterId=${confirmationLetterId}`,
        data.confirmerAuthority
      );
    }, "保存しました");
    reset();
    postSaveHandler();
    console.log("post end", data);
  };
  // ヘッダー定義
  const columns = [
    {
      ...keyColumn("department", textColumn),
      title: "部署",
      disabled: disabled,
    },
    {
      ...keyColumn("name", textColumn),
      title: "担当者氏名",
      disabled: disabled,
    },
    {
      ...keyColumn("eMailAddress", textColumn),
      title: "メールアドレス",
      disabled: ({ rowData }) =>
        rowData?.userStatus === Constants.USER_STATUS.VALID || disabled,
    },
    {
      ...keyColumn(
        "authority",
        selectColumn({
          choices: authorityChoices,
          disabled: disabled,
        })
      ),
      title: "担当者種別",
      disabled: disabled,
      deleteValue: () => null,
      copyValue: ({ rowData }) =>
        authorityChoices?.find((choice) => choice.value === rowData?.authority)
          ?.label,
      pasteValue: ({ rowData, value }) => ({
        ...rowData,
        authority:
          authorityChoices?.find((choice) => choice.label === value)?.value ??
          null,
      }),
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="confirmerAuthority"
        control={control}
        defaultValue={targetData}
        render={({ field }) => (
          <DynamicDataSheetGrid
            onChange={field.onChange}
            value={field.value}
            columns={columns}
            gutterColumn={false}
            lockRows={disabled}
            autoAddRow
            height={300}
            createRow={() => ({
              id: "",
              confirmationLetterId: confirmationLetterId,
              authority: Constants.CONFIRMER_AUTHORITY_TYPE.EDIT_APPROVAL,
            })}
            duplicateRow={(rowData) => ({
              ...rowData,
              id: "",
              eMailAddress: "",
            })}
          />
        )}
      />
      <FormHelperText className="Mui-error">
        {errors?.confirmerAuthority?.map((row) => {
          let errorFields = Object.keys(row);
          return (
            <>
              {errorFields?.map((key) => (
                <>
                  {row[key]?.message}
                  <br />
                </>
              ))}
            </>
          );
        })}
      </FormHelperText>
      {!disabled && <Button type="submit">保存</Button>}
    </form>
  );
};

export default ConfirmerSpreadsheet;
