export const Constants = {
  POST_CODE_REGEXP: new RegExp("^[0-9]{3}-[0-9]{4}$"),
  TEL_REGEXP: new RegExp("^[0-9-]*$"),
  ONE_LINE_TEXT_MAX: 255,
  MULTI_LINE_TEXT_MAX: 10000,

  USER_STATUS: { VALID: "VALID" },
  TABLES: { PROCEDURE: "t_procedure" },
  MESSAGE_TYPE: { PROCEDURE_INFO: "PROCEDURE_INFO" },
  MESSAGE_CLASSIFICATION: { REQUEST: "REQUEST", RESPONSE: "RESPONSE" },
  PROCECURE_STATUS: {
    REGISTERED: "REGISTERED",
    WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
    APPROVED: "APPROVED",
    STARTED: "STARTED",
    CLOSED: "CLOSED",
  },
  CONFIRMATION_LETTER_STATUS: {
    REGISTERED: "REGISTERED",
    WAITING_FOR_SHIPMENT: "WAITING_FOR_SHIPMENT",
    SHIPPED: "SHIPPED",
    WAITING_FOR_APPROVAL_OF_CONFIRMER: "WAITING_FOR_APPROVAL_OF_CONFIRMER",
    RESPONSED: "RESPONSED",
    EXCLUDED: "EXCLUDED",
  },

  ROLE: {
    AUDITOR: "auditor",
    CLIENT: "client",
    CONFIRMER: "confirmer",
    ADMIN: "admin",
  },

  APPROVAL_TYPE: { ALL_APPROVER: "ALL_APPROVER" },

  CONFIRMATION_LETTER_TYPE: { OWNED_STOCK: "OWNED_STOCK" },

  CLIENT_AUTHORITY_TYPE: {
    EDIT: "EDIT",
    APPROVAL: "APPROVAL",
    EDIT_APPROVAL: "EDIT_APPROVAL",
    READ: "READ",
  },
  CONFIRMER_AUTHORITY_TYPE: {
    EDIT: "EDIT",
    APPROVAL: "APPROVAL",
    EDIT_APPROVAL: "EDIT_APPROVAL",
  },

  SENDING_METHOD: {
    EMAIL_OR_POST: "EMAIL_OR_POST",
    POST: "POST",
  },

  OWNED_STOCK_KUBUN: {
    OWNED_STOCK: "OWNED_STOCK",
    EQUITY: "EQUITY",
    CORPORATE_BOND: "CORPORATE_BOND",
    STOCK_ACQUISITION_RIGHTS: "STOCK_ACQUISITION_RIGHTS",
    OTHER_STOCK: "OTHER_STOCK",
  },

  MAIL_MESSAGE: {
    OWNED_STOCK: {
      SUBJECT: "【ご対応のお願い】#{被監査企業} 所有株式等確認のお願い",
      TEXT: [
        "#{相手先名称}",
        "#{宛名}",
        "",
        "平素よりお世話になっております。 ",
        "#{被監査企業}の会計監査に関連し、HLB Meisei有限責任監査法人より、当社の貴社に対する所有株式等の確認について、ご対応をお願い申し上げます。",
        "お手数をおかけいたしますが、当社の記録による下記内容が貴社の記録と相違ないことをご確認いただき、#{回収希望日}までに確認状システムを通じて、HLB Meisei有限責任監査法人宛にご回答くださいますようお願い申し上げます。 ",
        "もし、下記の内容に相違がある場合は、システム内の「相違内容」フォームにご入力いただきますようお願い申し上げます。",
        "なお、本確認依頼は、貴社への支払督促ではございませんので、念のため申し添えます。",
        "",
        "【確認内容】",
        "対象：所有株式等の確認",
        "基準日：#{基準日}",
        "確認状管理番号：#{リファー番号}",
        "確認方法：以下のリンクからアクセスし、ご確認ください。",
        "※確認状システムを初めて使用される場合は、システムへの招待メールが別途送付されますので、招待の承諾後システムのご利用をお願いいたします。",
        "#{URL}",
        "",

        "【ご不明点等のお問い合わせについて】",
        "ご不明な点がございましたら、下記までご連絡ください。",
        "〒111-0041 東京都台東区元浅草3-7-1 住友不動産上野御徒町ビル9階",
        "HLB Meisei有限責任監査法人",
        "TEL：03-6897-8791",
        "Email : #{監査チームメール}",
        "",
        "【注意事項】",
        "このメールはシステムから自動送信されたものです。返信いただいても確認できませんので、お問い合わせは上記までお願いいたします。",
      ].join("\n"),
    },
  },
};

export default Constants;
