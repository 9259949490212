import { Card, DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

function FormDialog({
  onClose,
  open,
  title,
  children,
  id,
  data,
  fullWidth = false,
  maxWidth = "lg",
}) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={{
        ".MuiPaper-root": {
          padding: 4,
        },
      }}
    >
      <DialogTitle align="center">{title}</DialogTitle>
      <DialogContent>
        <Card sx={{ paddingLeft: "1rem" }}>{children}</Card>
      </DialogContent>
    </Dialog>
  );
}

export default FormDialog;
