import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import useApi, { httpClient } from "../hooks/useApi";
import { roleActions, rolesActions } from "../store/store";

function Index() {
  const path = useLocation().pathname;
  const { connect } = useApi();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const result = await connect(() => {
        return httpClient.get("/.auth/me");
      });
      if (!result?.data?.clientPrincipal) {
        window.location.href = `/.auth/login/aad?post_login_redirect_uri=${path}`;
      } else {
        dispatch(
          rolesActions.setRoles(result?.data?.clientPrincipal?.userRoles)
        );
      }
      dispatch(roleActions.setRole(path.split("/")[1]));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  return <Outlet />;
}

export default Index;
