import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DynamicDataSheetGrid,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import { Controller, useForm } from "react-hook-form";
import useApi, { httpClient } from "../../hooks/useApi";
import selectColumn from "./Columns/SelectColumn";

// 会社担当者一覧
const ClientAuthoritySpreadsheet = ({
  targetData,
  clientAuthorityTypeList,
  isDetermined = false,
  procedureId,
  postDeterminedHandler = () => {},
}) => {
  const { control, handleSubmit, setValue } = useForm({
    mode: "onBlur",
  });
  const { connect } = useApi();

  useEffect(
    () => {
      setValue("clientAuthority", targetData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetData]
  );

  // API POST
  const onSubmit = async (data) => {
    await connect(() => {
      return httpClient.post(
        "../../api/auditor/SaveClientAuthority",
        data.clientAuthority
      );
    }, "保存しました");
    console.log("post end", data);
  };

  // 担当者権限の確定、再編集
  const [dialogOpen, setDialogOpen] = useState(false);
  const determineClientAuthority = async (determine) => {
    await connect(
      () => {
        return httpClient.post("../../api/auditor/DetermineClientAuthority", {
          procedureId: procedureId,
          determine: determine,
        });
      },
      determine ? "確定しました" : "確定を取り消しました"
    );
    setDialogOpen(false);
    postDeterminedHandler();
  };
  // ヘッダー定義
  const columns = [
    {
      ...keyColumn("name", textColumn),
      title: "担当者名",
      disabled: true,
    },
    {
      ...keyColumn("companyName", textColumn),
      title: "所属企業",
      disabled: true,
    },
    {
      ...keyColumn("department", textColumn),
      title: "所属部署",
      disabled: true,
    },
    {
      ...keyColumn("eMailAddress", textColumn),
      title: "メールアドレス",
      disabled: true,
    },
    {
      ...keyColumn("tel", textColumn),
      title: "電話番号",
      disabled: true,
    },
    {
      ...keyColumn("isApprovedLabel", textColumn),
      title: "承認",
      disabled: true,
      minWidth: 20,
      grow: 0.3,
    },
    {
      ...keyColumn(
        "authority",
        selectColumn({
          choices: clientAuthorityTypeList?.map((type) => {
            return { value: type.code, label: type.name };
          }),
          disabled: isDetermined,
        })
      ),
      title: "権限種別",
      disabled: isDetermined,
    },
  ];

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="clientAuthority"
          control={control}
          defaultValue={targetData}
          render={({ field }) => (
            <DynamicDataSheetGrid
              // value={data}
              // onChange={setData}
              {...field}
              columns={columns}
              gutterColumn={false}
              lockRows
              height={600}
            />
          )}
        />
        {!isDetermined && <Button type="submit">保存</Button>}
        {!isDetermined && (
          <Button onClick={() => determineClientAuthority(true)}>
            担当者権限の確定
          </Button>
        )}
        {isDetermined && (
          <Button color="warning" onClick={() => setDialogOpen(true)}>
            担当者権限の再編集
          </Button>
        )}
      </form>
      {/* 再編集ダイアログ */}
      <Dialog
        maxWidth="xs"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogContent>
          会社担当者の確定を取り消します。 <br />
          ※手続の承認も全て取り消されます。
        </DialogContent>
        <DialogActions>
          <Button onClick={() => determineClientAuthority(false)}>実行</Button>
          <Button onClick={() => setDialogOpen(false)}>キャンセル</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientAuthoritySpreadsheet;
