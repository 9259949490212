import { Link } from "@mui/material";
import { Link as domLink } from "react-router-dom";

const getConfirmationLetterListHead = () => {
  return [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "referNoLink",
      numeric: false,
      disablePadding: true,
      label: "リファー番号",
      visible: true,
    },
    {
      id: "confirmationDestination",
      numeric: false,
      disablePadding: true,
      label: "相手先名",
      visible: true,
    },
    {
      id: "confirmationLetterStatusLabel",
      numeric: false,
      disablePadding: true,
      label: "確認状態",
      visible: true,
    },
    {
      id: "postCode",
      numeric: false,
      disablePadding: true,
      label: "郵便番号",
      visible: true,
    },
    {
      id: "prefecture",
      numeric: false,
      disablePadding: true,
      label: "都道府県",
      visible: true,
    },
    {
      id: "cityWardTownVillage",
      numeric: false,
      disablePadding: true,
      label: "市区町村",
      visible: true,
    },
    {
      id: "restAddress",
      numeric: false,
      disablePadding: true,
      label: "番地・ビル名など",
      visible: true,
    },
    {
      id: "addressEnglish",
      numeric: false,
      disablePadding: true,
      label: "住所（英語表記）",
      visible: true,
    },
  ];
};

export const confirmationLetterDataMapper = (data, role) => {
  return data?.data?.map((row) => ({
    ...row,
    referNoLink: (
      <Link to={`/${role}/confirmationLetter/${row.id}`} component={domLink}>
        {row.referNo}
      </Link>
    ),
  }));
};
export default getConfirmationLetterListHead;
