const getBulkRegisterListHead = () => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "referNo",
      numeric: false,
      disablePadding: true,
      label: "リファー番号",
      visible: true,
      minWidth: "3rem",
    },
    {
      id: "confirmationDestination",
      numeric: false,
      disablePadding: true,
      label: "確認先名称",
      visible: true,
      minWidth: "7rem",
    },
    {
      id: "shokenType",
      numeric: false,
      disablePadding: true,
      label: "証券種別",
      visible: true,
      minWidth: "7rem",
    },
    {
      id: "result",
      numeric: false,
      disablePadding: true,
      label: "登録結果",
      visible: true,
      minWidth: "3rem",
    },
    {
      id: "message",
      numeric: false,
      disablePadding: true,
      label: "備考",
      visible: true,
      minWidth: "5rem",
    },
  ];
  return headCells;
};
export default getBulkRegisterListHead;
