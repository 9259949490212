/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import DefaultPaper from "../../components/DefaultPaper";
import useApi, { httpClient } from "../../hooks/useApi";
import Constants from "../../utils/Constants";
import MESSAGES from "../../utils/Messages";

// バリデーション定義
const schema = z.object({
  name: z.string().min(1, MESSAGES.REQUIRED),
  nameEnglish: z.string().min(1, MESSAGES.REQUIRED),
  tel: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .regex(Constants.TEL_REGEXP, MESSAGES.TEL_ERROR),
  postCode: z
    .string()
    .min(1, MESSAGES.REQUIRED)
    .regex(Constants.POST_CODE_REGEXP, MESSAGES.POST_CODE_ERROR),
  prefectureCode: z.string().min(1, MESSAGES.REQUIRED),
  cityTownVillageWard: z.string().min(1, MESSAGES.REQUIRED),
  streetAddressBuildingNameNumber: z.string().min(1, MESSAGES.REQUIRED),
  addressEnglish: z.string().min(1, MESSAGES.REQUIRED),
});

const Companysettings = ({ title }) => {
  // フォーム関連
  const [prefectureList, setPrefectureList] = useState(null);
  const { connect } = useApi();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });

  // API GET
  useEffect(() => {
    const fetchData = async () => {
      const result = await connect(() => {
        return httpClient.get("../api/mscsadmin/Companysettings");
      });
      setPrefectureList(result?.data?.prefectureList);
      reset({ ...result?.data?.data });
      // TODO:開発用
      console.log("fetch end", result?.data);
    };
    fetchData();
  }, []);

  // API POST
  const onSubmit = async (data) => {
    connect(() => {
      return httpClient.post("../api/mscsadmin/Companysettings", data);
    }, "保存しました");
    console.log("post end", data);
  };

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <DefaultPaper title="法人情報">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={5}>
              <Controller
                name="name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    label="法人名"
                    fullWidth
                    required
                    {...field}
                    error={!!errors.name}
                    helpertext={errors.name ? errors.name.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Controller
                name="nameEnglish"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    label="法人名（英語）"
                    fullWidth
                    required
                    {...field}
                    error={!!errors.nameEnglish}
                    helpertext={
                      errors.nameEnglish ? errors.nameEnglish.message : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12}>
              <Controller
                name="tel"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    label="電話番号"
                    required
                    {...field}
                    error={!!errors.tel}
                    helpertext={errors.tel ? errors.tel.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="postCode"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    label="郵便番号"
                    required
                    {...field}
                    error={!!errors.postCode}
                    helpertext={errors.postCode ? errors.postCode.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <FormControl>
                <InputLabel required>都道府県</InputLabel>
                <Controller
                  name="prefectureCode"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      required
                      {...field}
                      error={!!errors.prefectureCode}
                      helpertext={
                        errors.prefectureCode
                          ? errors.prefectureCode.message
                          : ""
                      }
                    >
                      {prefectureList?.map((prefecture) => (
                        <MenuItem value={prefecture.code} key={prefecture.code}>
                          {prefecture.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="cityTownVillageWard"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    label="市町村区"
                    required
                    {...field}
                    error={!!errors.cityTownVillageWard}
                    helpertext={
                      errors.cityTownVillageWard
                        ? errors.cityTownVillageWard.message
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="streetAddressBuildingNameNumber"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    label="番地・ビル名など"
                    fullWidth
                    required
                    {...field}
                    error={!!errors.streetAddressBuildingNameNumber}
                    helpertext={
                      errors.streetAddressBuildingNameNumber
                        ? errors.streetAddressBuildingNameNumber.message
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="addressEnglish"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    label="住所（英文確認状用）"
                    fullWidth
                    required
                    {...field}
                    error={!!errors.addressEnglish}
                    helpertext={
                      errors.addressEnglish ? errors.addressEnglish.message : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <br />
          <Button type="submit">保存</Button>
        </form>
      </DefaultPaper>
    </>
  );
};
export default Companysettings;
