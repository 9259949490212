import { TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";

export default function TextFieldWithTooltip(props) {
  const [value, setValue] = useState(props.value);

  const handleChange = (e) => {
    setValue(e.currentTarget.value);
  };

  return (
    <>
      <Tooltip
        title={value}
        placement="bottom"
        //TODO:ツールチップ位置調整
      >
        <TextField value={value} onChange={handleChange} {...props} />
      </Tooltip>
    </>
  );
}
