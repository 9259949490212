const getMessageListHead = () => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "messageClassificationLabel",
      numeric: false,
      disablePadding: true,
      label: "区分",
      visible: true,
      minWidth: "3rem",
    },
    {
      id: "content",
      numeric: false,
      disablePadding: true,
      label: "メッセージ",
      visible: true,
      minWidth: "10rem",
    },
    {
      id: "senderRoleLabel",
      numeric: false,
      disablePadding: true,
      label: "役割",
      visible: true,
      minWidth: "3rem",
    },
    {
      id: "senderName",
      numeric: false,
      disablePadding: true,
      label: "担当者",
      visible: true,
      minWidth: "5rem",
    },
    {
      id: "createdAtFormatted",
      numeric: false,
      disablePadding: true,
      label: "送信日時",
      visible: true,
      minWidth: "5rem",
    },
  ];
  return headCells;
};
export default getMessageListHead;
