import { Link } from "@mui/material";
import { Link as domLink } from "react-router-dom";

const getProcedureListHead = () => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      visible: false,
    },
    {
      id: "procedureId",
      numeric: false,
      disablePadding: true,
      label: "手続きId",
      visible: false,
    },
    {
      id: "companyCode",
      numeric: false,
      disablePadding: true,
      label: "会社コード",
      visible: true,
      minWidth: "4rem",
    },
    {
      id: "referenceDateFormatted",
      numeric: false,
      disablePadding: true,
      label: "基準日",
      visible: true,
    },
    {
      id: "procedureNameLink",
      numeric: false,
      disablePadding: true,
      label: "確認手続名称",
      visible: true,
    },
    {
      id: "confirmationTypeLabel",
      numeric: false,
      disablePadding: true,
      label: "確認状様式",
      visible: true,
    },
    {
      id: "approvalTypeLabel",
      numeric: false,
      disablePadding: true,
      label: "承認種別",
      visible: true,
    },
    {
      id: "procedureStatusLabel",
      numeric: false,
      disablePadding: true,
      label: "ステータス",
      visible: true,
    },
  ];
  return headCells;
};

export const procedureDataMapper = (data, role) => {
  return data?.data?.map((row) => ({
    ...row,
    procedureNameLink: (
      <Link to={`/${role}/procedure/${row.id}`} component={domLink}>
        {row.procedureName}
      </Link>
    ),
  }));
};

// TODO：開発用
function createProcedureData(
  id,
  procedureId,
  referenceDate,
  companyCode,
  procedureName,
  status
) {
  return {
    id,
    procedureId,
    referenceDate,
    companyCode,
    procedureName,
    status,
  };
}
// TODO:開発用
export const procedureData = [
  createProcedureData(
    "AA1",
    "AA1",
    "2024年2月28日",
    "AA1",
    "社債",
    "確認手続完了"
  ),
  createProcedureData(
    "AA2",
    "AA2",
    "2024年3月31日",
    "AA2",
    "所有株式",
    "承認済"
  ),
  createProcedureData(
    "AA3",
    "AA3",
    "2024年3月31日",
    "AA3",
    "債権債務",
    "未承認"
  ),
  createProcedureData(
    "AA4",
    "AA4",
    "2024年3月31日",
    "AA4",
    "債権債務",
    "送付済"
  ),
  createProcedureData(
    "EE1",
    "EE1",
    "2024年3月31日",
    "EE1",
    "預り在庫",
    "送付済"
  ),
  createProcedureData(
    "FF1",
    "FF1",
    "2024年3月31日",
    "FF1",
    "預り在庫",
    "送付済"
  ),
  createProcedureData(
    "FF2",
    "FF2",
    "2024年3月31日",
    "FF2",
    "預り在庫",
    "送付済"
  ),
];

export default getProcedureListHead;
